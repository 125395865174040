@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Regular.ttf);
}
@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Bold.ttf);
  font-weight: 700;
}

@layer base {
  main {
    @apply bg-gray-100 m-4 mt-20 p-4;
  }
  p {
    @apply my-4;
  }

  h1 {
    @apply text-2xl font-bold uppercase mb-2;
  }
  h2 {
    @apply text-xl font-bold mt-4 mb-2;
  }
  h3 {
    @apply text-lg font-bold mt-6;
  }

  hr {
    @apply w-full border-t-2 border-t-gray-300;
  }

  #copyright ul {
    @apply list-disc ml-6 my-4;
  }
  #copyright ul li {
    @apply my-2;
  }
  #copyright a {
    @apply text-blue-600;
  }

  .Toastify__toast-body {
    @apply whitespace-pre-line;
  }
}
